<template>
	<div v-if="call" class="call">
		<div class="call-action-bar">
			<div class="call-actions">
				<router-link :to="{ name: 'app.calls' }" class="call-action">
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'arrow-to-left']"
							aria-hidden="true"
						/>
					</span>
					Back
				</router-link>
			</div>
			<div class="call-actions">
				<call-types-dropdown
					v-if="
						callTypesAll.length > 0 &&
							((isUrgent && isComplete) || !isUrgent)
					"
					:call="call"
					:call-types="callTypesAll"
				/>
				<button
					v-if="!call.completed_at"
					class="call-action"
					@click.prevent="panels.complete = true"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'check']"
							aria-hidden="true"
						/>
					</span>
					Complete
				</button>
				<button
					v-if="isUrgent && !isCallDismissed && !call.completed_at"
					class="call-action"
					@click.prevent="onDismissSubmit"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'circle']"
							aria-hidden="true"
						/>
					</span>
					Dismiss
				</button>
				<button
					v-if="isUrgent"
					class="call-action"
					@click.prevent="panels.reassign = true"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'people-carry']"
							aria-hidden="true"
						/>
					</span>
					Reassign
				</button>
				<button
					class="call-action"
					@click.prevent="panels.notes.create = true"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'sticky-note']"
							aria-hidden="true"
						/>
					</span>
					Add Note
				</button>
				<button class="call-action" @click.prevent="exportToPdf">
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'file-pdf']"
							aria-hidden="true"
						/>
					</span>
					Export
				</button>
			</div>
		</div>
		<div class="call-container">
			<div class="flex px-3 py-2 w-100">
				<call-tabs :active="tab" @activate="activateTab" />
			</div>
			<div
				class="call-columns"
				:class="{ 'call-message': tab === 'message' }"
			>
				<div class="call-column call-info">
					<div v-if="tab === 'message'" class="call-info-block">
						<div class="call-info-card">
							<div class="w-100 flex align-center">
								<h3
									class="inline-flex items-center font-nunito font-semibold w-2/5 text-lg py-1 text-gray-800"
								>
									Call Message
								</h3>

								<div
									class="flex items-center justify-end w-3/5"
								>
									<audio-player
										v-if="
											hasMessageRecording &&
												parseInt(
													messageRecording.duration
												) > 0
										"
										:url="messageRecording.url"
									/>
									<button
										:disabled="message.editing"
										type="button"
										class="btn btn-sm"
										data-tooltip="Edit"
										data-position="bottom"
										@click="onEditMessage"
									>
										<div class="btn-ripple"></div>
										<font-awesome-icon
											fixed-width
											class="btn-icon"
											:icon="['fal', 'pencil']"
										/>
									</button>

									<button
										:disabled="!message.editing"
										type="button"
										class="btn btn-sm"
										data-tooltip="Cancel"
										data-position="bottom"
										data-cy="cancel-button"
										@click.stop="onMessageReset"
									>
										<div class="btn-ripple"></div>
										<font-awesome-icon
											fixed-width
											class="btn-icon"
											:icon="['fal', 'times-circle']"
										/>
									</button>

									<button
										type="button"
										class="btn btn-sm"
										:disabled="
											!message.editing ||
												!message.changed ||
												message.saving
										"
										data-tooltip="Save"
										data-position="bottom"
										@click="onSaveMessage"
									>
										<div class="btn-ripple"></div>
										<font-awesome-icon
											fixed-width
											class="btn-icon"
											:icon="['fal', 'save']"
										/>
									</button>
								</div>
							</div>
							<div
								class="flex flex-col items-center justify-start py-4 w-1/3"
							>
								<font-awesome-icon
									class="text-3xl mb-2"
									:icon="['fad', 'user-circle']"
									style="--fa-primary-color: #6915ef; --fa-secondary-color: #a0aec0;"
								></font-awesome-icon>
								<span
									v-if="patientName"
									class="text-center text-sm font-semibold text-gray-700"
								>
									{{ patientName }}
								</span>
								<span
									v-else-if="call.callback_number"
									class="text-center text-sm font-semibold text-gray-700"
								>
									{{
										call.callback_number | formatPhoneNumber
									}}
								</span>
								<span
									v-if="patientName && call.callback_number"
									class="text-center text-xs font-semibold text-gray-700"
								>
									{{
										call.callback_number | formatPhoneNumber
									}}
								</span>
							</div>
							<div class="w-2/3 pl-2">
								<div class="flex items-center mt-4 pb-0">
									<div
										v-if="message.editing"
										id="call-message-editable"
										ref="message"
										class="w-100 p-2 px-2 text-gray-800"
										contenteditable="true"
										@blur="message.update($event)"
										@keyup="message.onKeyUp($event)"
										@keyup.esc.prevent.stop="message.reset"
										@keydown.ctrl.enter.exact="
											onSaveMessage
										"
										@keydown.meta.enter="onSaveMessage"
										v-text="message.value"
									></div>
									<div
										v-else-if="call.patient_message"
										class="w-100 p-2 px-2 text-gray-800 border border-transparent"
									>
										<div
											id="call-message"
											contenteditable="false"
											v-html="call.patient_message"
										/>
									</div>
									<button
										v-else
										type="button"
										class="w-100 p-2 px-2 text-gray-500 border border-dashed border-gray-400 text-left focus:outline-none"
										@click="onEditMessage"
									>
										Click to edit the call transcription.
									</button>
								</div>
								<div
									class="w-100 text-xs text-right uppercase font-roboto font-medium tracking-wider h-4 mt-1 d-block"
									:class="{
										'text-green-700':
											message.status === 'SAVING',
										'text-red-700':
											message.status === 'UNSAVED',
										'text-gray-600':
											message.status === 'UNCHANGED',
									}"
									v-text="message.status"
								></div>
							</div>
							<div class="w-100 pt-2">
								<div
									class="flex items-center justify-between pt-4 px-2 pb-0"
								>
									<div
										v-if="call.pharmacy_phone_number"
										class="flex items-center"
									>
										<h3
											class="text-gray-700 text-sm font-medium"
										>
											<i class="far fa-phone"></i>
											Pharmacy Number &nbsp;
										</h3>
										<div
											class="font-roboto text-gray-600 flex items-center"
											style="text-wrap: nowrap"
										>
											<span
												v-if="pharmacyNumber.editing"
												id="callback-number-editable"
												ref="pharmacyNumber"
												contenteditable="true"
												class="font-nunito font-bold w-100 py-1"
												@blur="
													pharmacyNumber.update(
														$event
													)
												"
												@keyup="
													pharmacyNumber.onKeyUp(
														$event
													)
												"
												@keyup.esc.prevent.stop="
													pharmacyNumber.reset
												"
												@keydown.enter.prevent.stop.exact="
													onSaveNumber
												"
												@keydown.ctrl.enter.exact="
													onSaveNumber
												"
												@keydown.meta.enter="
													onSaveNumber
												"
												v-text="pharmacyNumber.value"
											>
											</span>
											<span
												v-else-if="
													phamracyNumberAttribute
												"
												id="pharmacy-Number"
												contenteditable="false"
												class="font-nunito font-bold w-100 py-1"
											>
												{{ phamracyNumberAttribute }}
											</span>
											<span
												v-else
												contenteditable="false"
												class="font-nunito font-medium text-gray-500 border-b border-dotted py-1 border-gray-400 w-100"
											>
												Phamracy Number
											</span>
											<button
												v-if="pharmacyNumber.editing"
												type="button"
												class="btn btn-sm w-auto ml-1"
												aria-label="Save number"
												@click="onSaveNumber"
											>
												<div class="btn-ripple"></div>
												<font-awesome-icon
													class="btn-icon"
													:icon="['fal', 'save']"
												/>
											</button>

											<button
												v-else
												type="button"
												class="btn btn-sm w-auto ml-1"
												aria-label="Edit transcription"
												@click="onEditPharmacyNumber"
											>
												<div class="btn-ripple"></div>
												<font-awesome-icon
													class="btn-icon"
													:icon="['fal', 'pencil']"
												/>
											</button>

											<div
												class="w-100 text-xs text-right uppercase font-roboto font-medium tracking-wider h-4 mt-1 d-block"
												:class="{
													'text-green-700':
														pharmacyNumber.status ===
														'SAVING',
													'text-red-700':
														pharmacyNumber.status ===
														'UNSAVED',
													'text-gray-600':
														pharmacyNumber.status ===
														'UNCHANGED',
												}"
												v-text="pharmacyNumber.status"
											></div>
										</div>
									</div>
									<div
										:class="{
											'text-gray-700': !call.pharmacy_phone_number,
											'text-sm': !call.pharmacy_phone_number,
											'font-medium': !call.pharmacy_phone_number,
											'ml-auto': !call.pharmacy_phone_number,
										}"
										style="font-weight: 500; color: #4a5568; font-size: .875rem"
									>
										{{ call.created_at | toDateTime }}
									</div>
								</div>

								<div class="flex items-center p-2">
									<div class="chip-set">
										<chip
											v-if="isComplete"
											condensed
											:icon="['fal', 'check']"
											class="bg-green-100 text-green-500 border-green-200"
										>
											Complete
										</chip>
										<chip
											v-if="isCallDismissed"
											condensed
											:icon="['far', 'circle']"
											class="bg-purple-100 text-purple-500 border-purple-200"
										>
											Dismissed
										</chip>

										<chip
											v-if="call.is_urgent"
											condensed
											:icon="['fal', 'clock']"
											class="bg-orange-100 text-orange-500 border-orange-200"
										>
											Urgent
										</chip>

										<chip v-else condensed>
											Non-Urgent
										</chip>
										<chip
											v-for="type in call.types"
											:key="type.id"
											condensed
										>
											{{ type.name }}
										</chip>
									</div>
								</div>
								<div
									class="w-100 text-xs text-right uppercase font-roboto font-medium tracking-wider h-4 mt-1 d-block mb-2"
								>
									<span
										v-if="message.editing"
										class="text-gray-600"
									>
										Press <code>ESC</code> to cancel;
										<code>CTRL/CMD</code> +
										<code>Enter</code> to save.
									</span>
								</div>

								<div v-if="hasOutboundCalls">
									<hr class="mt-2 mb-3" />
									<h3
										class="inline-flex items-center font-nunito font-semibold text-lg py-2 mb-2 text-gray-800"
									>
										Outbound Calls ({{
											call.outbound_calls.length
										}})
									</h3>
									<div
										v-for="outboundCall in call.outbound_calls"
										:key="outboundCall.sid"
										class="flex px-2 pb-0 flex-col"
									>
										<div class="flex flex-col mb-2">
											<div
												class="flex items-center justify-between mb-2"
											>
												<font-awesome-icon
													class="mr-4 text-material"
													:icon="['fal', 'phone']"
												></font-awesome-icon>

												<span
													class="mr-auto text-sm text-gray-800 font-medium"
												>
													{{
														call.callback_number
															| formatPhoneNumber
													}}
												</span>

												<div
													v-for="record in outboundCall.recordings"
													v-show="
														parseInt(
															record.duration
														) > 0
													"
													:key="record.sid"
													class="flex items-center"
												>
													<audio-player
														:url="record.url"
													/>
													<span
														class="text-xs text-gray-600 ml-2"
													>
														{{ record.duration }}s
													</span>
												</div>
											</div>
											<div
												class="flex items-center justify-end text-gray-700 text-sm font-medium"
											>
												{{
													outboundCall.created_at
														| toDateTime
												}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="call-info-card">
							<h3
								class="font-nunito font-semibold w-100 text-lg py-1 text-gray-800"
							>
								Call Notes
							</h3>
							<div
								v-for="(currentNote, $index) in notes"
								:key="currentNote.id"
								class="flex p-2 font-roboto w-100"
								:class="{
									'border-b': $index + 1 != notes.length,
								}"
							>
								<div
									class="flex flex-col items-center justify-center py-2 w-1/4"
								>
									<font-awesome-icon
										class="text-3xl mb-2"
										:icon="['fad', 'user-md-chat']"
										style="--fa-primary-color: #6915ef; --fa-secondary-color: #f79421;"
									></font-awesome-icon>
									<span
										class="text-sm text-gray-800 text-center whitespace-no-wrap"
									>
										{{ getNoteUserFullName(currentNote) }}
									</span>
								</div>

								<div class="pl-3 w-4/5 flex flex-col">
									<div
										class="flex items-center w-100 pt-2 px-2 pb-0"
									>
										{{ currentNote.content }}
									</div>

									<div
										class="flex items-center justify-start mt-auto mb-2 pt-2 px-2 pb-0 text-gray-600 text-sm font-medium"
									>
										{{
											currentNote.created_at | toDateTime
										}}
									</div>
								</div>
								<div
									v-if="canEditNote(currentNote)"
									class="pl-3 w-2/5 flex flex-row justify-end items-center"
								>
									<button
										type="button"
										class="btn btn-sm w-auto mr-1"
										data-tooltip="Edit Note"
										data-position="bottom"
										aria-label="Edit Note"
										@click.prevent="
											onEditNoteClick(call, currentNote)
										"
									>
										<div class="btn-ripple"></div>
										<font-awesome-icon
											class="btn-icon"
											:icon="['fal', 'pencil']"
										/>
									</button>
									<button
										type="button"
										class="btn btn-sm"
										data-tooltip="Delete Note"
										data-position="bottom"
										@click.prevent="
											onDeleteNoteClick(call, currentNote)
										"
									>
										<div class="btn-ripple"></div>
										<font-awesome-icon
											fixed-width
											class="btn-icon"
											:icon="['fal', 'times-circle']"
										/>
									</button>
								</div>
								<div
									v-else
									class="pl-3 w-2/5 flex flex-row justify-end items-center"
								/>
							</div>
							<div v-if="notes.length === 0">
								<span class="pt-2 text-gray-600 font-medium">
									No notes to display.
								</span>
							</div>
						</div>
					</div>
					<div v-if="tab === 'history'" class="call-info-block">
						<div class="call-info-card">
							<h3
								class="font-nunito font-semibold w-100 text-lg pt-1 pb-4 text-gray-800"
							>
								Call History
							</h3>
							<div
								v-for="(event, $index) in events"
								:key="event.id"
								class="flex w-100"
								:class="{
									'mb-4': $index + 1 < events.length,
								}"
							>
								<div
									class="flex items-center justify-center px-2 w-16"
								>
									<div class="-mt-5">
										<font-awesome-icon
											v-if="event.type === 'create'"
											class="text-2xl text-purple-600"
											:icon="['far', 'phone-alt']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'urgent_forward'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'share']"
										/>
										<font-awesome-icon
											v-else-if="event.type === 'archive'"
											class="text-2xl text-purple-600"
											:icon="['far', 'archive']"
										/>
										<font-awesome-icon
											v-else-if="event.type === 'update'"
											class="text-2xl text-purple-600"
											:icon="['far', 'user-plus']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'complete'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'check']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'response'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'comment-lines']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'response-call'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'phone-plus']"
										/>
										<font-awesome-icon
											v-else-if="event.type === 'dismiss'"
											class="text-2xl text-purple-600"
											:icon="['far', 'circle']"
										/>
										<font-awesome-icon
											v-else-if="event.type === 'note'"
											class="text-2xl text-purple-600"
											:icon="['far', 'clipboard']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'callback'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'phone-volume']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'call_ended'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'phone-slash']"
										/>
										<font-awesome-icon
											v-else
											class="text-2xl text-purple-600"
											:icon="['far', 'bell']"
										/>
									</div>
								</div>
								<div
									class="flex flex-col justify-between pl-3 w-100"
								>
									<div class="flex items-center px-2 pb-0">
										<span class="text-gray-700">
											{{ event.summary }}
											{{
												event.type === 'complete' &&
												call.completion_message &&
												call.completion_message !==
													'No message provided'
													? `- "${call.completion_message}"`
													: ''
											}}
										</span>
									</div>
									<div
										class="flex items-center justify-end pt-1 px-2 pb-0"
									>
										<span class="text-gray-600 text-xs">
											{{ event.created_at | toDateTime }}
										</span>
									</div>
									<hr
										v-if="$index + 1 < call.events.length"
										class="mt-3"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="call-column patient-info">
					<div class="flex flex-col w-full py-3">
						<div
							class="bg-white border border-solid border-gray-400 rounded shadow-lg flex flex-col relative w-100 break-words"
						>
							<div class="flex flex-col flex-auto p-3">
								<h3
									class="inline-flex items-center font-nunito font-semibold text-lg py-1 text-gray-800"
								>
									Caller Information
								</h3>
								<div class="mt-3 w-100 text-center">
									<div
										class="flex items-center justify-center text-5xl py-4 px-3 mx-auto w-20 h-20 bg-white rounded-full border-2 border-gray-300"
									>
										<font-awesome-icon
											fixed-width
											:icon="['fad', 'user-alt']"
										/>
									</div>
									<div
										class="flex flex-row justify-between flex-no-wrap mt-2"
									>
										<audio-player
											v-if="
												hasNameRecording &&
													parseInt(
														nameRecording.duration
													) > 0
											"
											:url="nameRecording.url"
										/>

										<h4
											class="inline-flex items-center justify-center w-100"
										>
											<span
												v-if="name.editing"
												id="patient-name-editable"
												ref="name"
												contenteditable="true"
												class="font-nunito font-bold w-100 py-1"
												@blur="name.update($event)"
												@keyup="name.onKeyUp($event)"
												@keyup.esc.prevent.stop="
													name.reset
												"
												@keydown.enter.prevent.stop.exact="
													onSaveName
												"
												@keydown.ctrl.enter.exact="
													onSaveName
												"
												@keydown.meta.enter="onSaveName"
												v-text="name.value"
											>
											</span>
											<span
												v-else-if="patientName"
												id="patient-name"
												contenteditable="false"
												class="font-nunito font-bold w-100 py-1"
											>
												{{ patientName }}
											</span>
											<span
												v-else
												contenteditable="false"
												class="font-nunito font-medium text-gray-500 border-b border-dotted py-1 border-gray-400 w-100"
											>
												Patient name
											</span>
										</h4>

										<button
											v-if="name.editing"
											type="button"
											class="btn btn-sm w-auto ml-1"
											aria-label="Save name"
											@click="onSaveName"
										>
											<div class="btn-ripple"></div>
											<font-awesome-icon
												class="btn-icon"
												:icon="['fal', 'save']"
											/>
										</button>

										<button
											v-else
											type="button"
											class="btn btn-sm w-auto ml-1"
											aria-label="Edit transcription"
											@click="onEditName"
										>
											<div class="btn-ripple"></div>
											<font-awesome-icon
												class="btn-icon"
												:icon="['fal', 'pencil']"
											/>
										</button>
									</div>
									<div
										class="w-100 text-xs text-right uppercase font-roboto font-medium tracking-wider h-4 mt-1 d-block"
										:class="{
											'text-green-700':
												name.status === 'SAVING',
											'text-red-700':
												name.status === 'UNSAVED',
											'text-gray-600':
												name.status === 'UNCHANGED',
										}"
										v-text="name.status"
									></div>
									<div
										class="flex flex-row justify-between flex-no-wrap mt-2"
									>
										<audio-player
											v-if="
												hasDobRecording &&
													parseInt(
														dobRecording.duration
													) > 0
											"
											:url="dobRecording.url"
										/>
										<h4
											class="inline-flex items-center justify-center w-100"
										>
											<span
												v-if="dob.editing"
												ref="dob"
												contenteditable="true"
												class="font-nunito font-bold w-100 py-1"
												@blur="dob.update($event)"
												@keyup="dob.onKeyUp($event)"
												@keyup.esc.prevent.stop="
													dob.reset
												"
												@keydown.enter.prevent.stop.exact="
													onSaveDob
												"
												@keydown.ctrl.enter.exact="
													onSaveDob
												"
												@keydown.meta.enter="onSaveDob"
												v-text="dob.value"
											>
											</span>
											<span
												v-else-if="patientDob"
												contenteditable="false"
												class="font-nunito font-bold w-100 py-1"
											>
												{{ patientDob }}
											</span>
											<span
												v-else
												contenteditable="false"
												class="font-nunito font-medium text-gray-500 border-b border-dotted py-1 border-gray-400 w-100"
											>
												MM/DD/YYYY
											</span>
										</h4>
										<button
											v-if="dob.editing"
											type="button"
											class="btn btn-sm w-auto ml-1"
											aria-label="Save DOB"
											@click="onSaveDob"
										>
											<div class="btn-ripple"></div>
											<font-awesome-icon
												class="btn-icon"
												:icon="['fal', 'save']"
											/>
										</button>

										<button
											v-else
											type="button"
											class="btn btn-sm w-auto ml-1"
											aria-label="Edit transcription"
											@click="onEditDob"
										>
											<div class="btn-ripple"></div>
											<font-awesome-icon
												class="btn-icon"
												:icon="['fal', 'pencil']"
											/>
										</button>
									</div>
									<div
										class="w-100 text-xs text-right uppercase font-roboto font-medium tracking-wider h-4 mt-1 d-block"
										:class="{
											'text-green-700':
												dob.status === 'SAVING',
											'text-red-700':
												dob.status === 'UNSAVED',
											'text-gray-600':
												dob.status === 'UNCHANGED',
										}"
										v-text="dob.status"
									></div>
									<hr class="mt-2" />
								</div>
								<div
									v-if="call && call.patient_provider"
									class="mt-3 w-100"
								>
									<h5 class="font-nunito font-semibold mb-3">
										Patient's Provider
									</h5>

									<ul class="font-roboto text-gray-600">
										<li class="mb-4 tracking-wider text-sm">
											<font-awesome-icon
												class="text-lg mr-2"
												:icon="['fad', 'user-md-chat']"
												style="--fa-primary-color: #6915ef; --fa-secondary-color: #f79421;"
											></font-awesome-icon>
											<span>
												{{
													call.patient_provider
														.full_name
												}}
											</span>
										</li>
									</ul>
								</div>
								<div
									v-if="call.is_urgent && call.provider"
									class="mt-3 w-100"
								>
									<h5 class="font-nunito font-semibold mb-3">
										Provider Notified
									</h5>

									<ul class="font-roboto text-gray-600">
										<li class="mb-4 tracking-wider text-sm">
											<font-awesome-icon
												class="text-lg mr-2"
												:icon="['fad', 'user-md-chat']"
												style="--fa-primary-color: #6915ef; --fa-secondary-color: #f79421;"
											></font-awesome-icon>
											<span>
												{{ call.provider.full_name }}
											</span>
										</li>
									</ul>
								</div>
								<div class="mt-3 w-100 d-none">
									<h5 class="font-nunito font-semibold">
										Recent Calls
									</h5>

									<ul class="font-roboto text-gray-600 py-3">
										<li>No calls were found</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<complete-call-panel
			:show="panels.complete"
			:sid="sid"
			@close="panels.complete = false"
		/>
		<add-note-panel
			:show="panels.notes.create"
			:sid="sid"
			:is-page="false"
			@close="panels.notes.create = false"
		/>
		<delete-note-panel
			:show="panels.notes.delete"
			:sid="sid"
			:note="note"
			:is-page="false"
			@close="panels.notes.delete = false"
		/>
		<edit-note-panel
			:show="panels.notes.update"
			:sid="sid"
			:note="note"
			:is-page="false"
			@close="panels.notes.update = false"
		/>
		<reassign-call-panel
			:show="panels.reassign"
			:call="call"
			@close="panels.reassign = false"
		/>
	</div>
</template>
<script>
import { sortBy } from 'lodash'
import Chip from '@/components/Chip.vue'
import Editable from 'App/Support/Editable'
import { mapActions, mapGetters } from 'vuex'
import CallTabs from '@/components/CallTabs.vue'
import AudioPlayer from '@/components/AudioPlayer'
import AddNotePanel from '@/components/Panels/AddNotePanel'
import EditNotePanel from '@/components/Panels/EditNotePanel'
import CallTypesDropdown from '@/components/CallTypesDropdown'
import DeleteNotePanel from '@/components/Panels/DeleteNotePanel'
import CompleteCallPanel from '@/components/Panels/CompleteCallPanel'
import ReassignCallPanel from '@/components/Panels/ReassignCallPanel'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		AddNotePanel,
		AudioPlayer,
		CompleteCallPanel,
		Chip,
		CallTabs,
		CallTypesDropdown,
		DeleteNotePanel,
		EditNotePanel,
		ReassignCallPanel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current active call to display.
		 *
		 * @return {Object}
		 */
		call() {
			return this.findBySid(this.$route.params.sid)
		},

		callTypesAll() {
			return this.getTypes.length > 0
				? this.getTypes.filter(this.isCallType)
				: []
		},

		/**
		 * Get DOB recording.
		 *
		 * @return {Object}
		 */
		dobRecording() {
			if (!this.hasDobRecording) {
				return
			}

			return this.call.recordings.find(record => record.type === 'dob')
		},

		/**
		 * Get the current call's event messages.
		 *
		 * @return {Array}
		 */
		events() {
			if (!this.call || !this.call.events) {
				return []
			}

			return sortBy(this.call.events, ['created_at'])
		},

		/**
		 * Get the current authenticated user's data.
		 *
		 * @return {Object}
		 */
		currentUser() {
			return this.$store.state.auth.user
		},

		/**
		 * Determine if the current call has recordings.
		 *
		 * @return {Boolean}
		 */
		hasRecordings() {
			return this.call.recordings && this.call.recordings.length > 0
		},

		/**
		 * Determine if the current call has dob recordings.
		 *
		 * @return {Boolean}
		 */
		hasDobRecording() {
			if (!this.hasRecordings) {
				return
			}

			return this.call.recordings.some(record => record.type === 'dob')
		},

		/**
		 * Determine if the current call has dob recordings.
		 *
		 * @return b
		 */
		hasMessageRecording() {
			if (!this.hasRecordings) {
				return
			}

			return this.call.recordings.some(
				record => record.type === 'message'
			)
		},

		/**
		 * Determine if the current call has name recordings.
		 *
		 * @return b
		 */
		hasNameRecording() {
			if (!this.hasRecordings) {
				return
			}

			return this.call.recordings.some(record => record.type === 'name')
		},

		/**
		 * Determine if any outbound calls have been made.
		 *
		 * @return {Boolean}
		 */
		hasOutboundCalls() {
			return this.call?.outbound_calls?.length > 0
		},

		/**
		 * Determine if the current call is complete.
		 *
		 * @return {Boolean}
		 */
		isComplete() {
			return !!this.call.completed_at
		},

		/**
		 * Determine if the current call is dismissed.
		 *
		 * @return {Boolean}
		 */

		isCallDismissed() {
			return (
				this.call.dismissed_by !== null &&
				this.call.dismissed_by !== undefined &&
				this.call.dismissed_by !== ''
			)
		},

		/**
		 * Determine if a call is urgent.
		 *
		 * @return {Boolean}
		 */
		isUrgent() {
			return this.call ? this.call.is_urgent : false
		},

		/**
		 * Get message recording.
		 *
		 * @return {Object}
		 */
		messageRecording() {
			if (!this.hasMessageRecording) {
				return
			}

			return this.call.recordings.find(
				record => record.type === 'message'
			)
		},

		/**
		 * Get name recording.
		 *
		 * @return {Object}
		 */
		nameRecording() {
			if (!this.hasNameRecording) {
				return
			}

			return this.call.recordings.find(record => record.type === 'name')
		},

		/**
		 * Get the current call's notes.
		 *
		 * @return {Array}
		 */
		notes() {
			if (!this.call || !this.call.notes) {
				return []
			}

			return [...this.call.notes].sort((a, b) => a.id - b.id)
		},

		/**
		 * Get the patient's DOB from the current call.
		 *
		 * @return {String}
		 */
		patientDob() {
			return this.call ? this.call.patient_dob : ''
		},

		/**
		 * Get the patient's name from the current call.
		 *
		 * @return {String}
		 */
		patientName() {
			return this.call ? this.call.patient_name : ''
		},

		phamracyNumberAttribute() {
			const res = this.call ? this.call.pharmacy_phone_number : ''
			const cleanedNumber = res.replace(/^\+1/, '')
			const formatted = cleanedNumber.replace(
				/(\d{3})(\d{3})(\d{4})/,
				'($1) $2-$3'
			)
			return formatted
		},

		/**
		 * Get the patient's phone number from the current call.
		 *
		 * @return {String}
		 */
		phoneNumber() {
			return this.call ? this.call.pharmacy_phone_number : ''
		},

		/**
		 * Get call's sid.
		 *
		 * @return {String}
		 */
		sid() {
			return this.$route.params.sid
		},

		...mapGetters('calls', ['findBySid', 'isRecentCall']),
		...mapGetters({
			getTypes: 'callTypes/get',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Set the current active tab to display.
		 *
		 * @param {String} tab
		 * @return {void}
		 *
		 */

		async onDismissSubmit() {
			try {
				await this.dismiss({
					sid: this.sid,
				})
				this.$alert.success('calls.dismiss.success')
			} catch (e) {
				this.$alert.error('calls.dismiss.error')
			}
		},

		...mapActions({
			dismiss: 'calls/dismiss',
			getCalls: 'calls/get',
		}),

		activateTab(tab) {
			this.tab = tab
		},

		onMessageReset() {
			this.message.reset()
		},

		/**
		 * Determine if the given note can be edited.
		 *
		 * @param {Object} note
		 * @return {Boolean}
		 */
		canEditNote(note) {
			if (!this.currentUser || !note.user) {
				return false
			}

			return this.currentUser.id === note.user.id
		},

		/**
		 * Export the call to a PDF file.
		 *
		 * @return {void}
		 */
		exportToPdf() {
			this.$app.use('App/PDF/CallPDF').make(this.call)
		},

		/**
		 * Fetch the current route's call from the API.
		 *
		 * @param {String} sid
		 * @return {Promise}
		 */
		async getCall(sid) {
			const partner =
				this.$store.getters.partner?.id ||
				parseInt(this.$route.params.partner)

			try {
				const response = await this.$api
					.partners(partner)
					.calls()
					.find(sid)

				const call = response.get('data', {})

				if (this.isRecentCall(call.id)) {
					this.removeRecentCall(call.id)
				}

				const partnerIdParam = parseInt(this.$route.params.partner)

				if (call.partner_id !== partnerIdParam) {
					return this.$router.push({
						params: { partner },
						name: 'app.calls',
					})
				}

				return await this.$store.dispatch('calls/add', call)
			} catch (e) {
				if (e.status === 404) {
					this.$router.push({
						name: 'app.calls',
						params: { partner },
					})
				}

				this.$alert.response(e)
			}
		},

		/**
		 * Fetch all call types.
		 *
		 * @return {void}
		 */
		async getCallTypes() {
			try {
				const response = await this.$api
					.partners()
					.callTypes()
					.get(this.$store.getters.partner.id)

				this.callTypes = response
					.get('data', [])
					.filter(this.isCallType)
			} catch (e) {
				this.$alert.error('default.error')
			}
		},

		/**
		 * Get the user's full name from the given note.
		 *
		 * @param {String}
		 */
		getNoteUserFullName(note) {
			if (!note.user) {
				return 'User Has Been Deleted'
			}

			return `${note.user.first_name} ${note.user.last_name}`
		},

		/**
		 * Determine if the type matches with the call type.
		 *
		 * @type {Boolean} type
		 * @return {Boolean}
		 */
		isCallType(type) {
			if (!this.call) {
				return
			}

			return type.is_urgent === this.call.is_urgent
		},

		/**
		 * Handle the on delete note click event.
		 *
		 * @param {Object} call
		 * @param {Object} note
		 * @return {void}
		 */
		onDeleteNoteClick(call, note) {
			this.panels.notes.delete = true

			this.note = { ...note, call_id: call.id }
		},

		/**
		 * Handle the on edit note click event.
		 *
		 * @param {Object} call
		 * @param {Object} note
		 * @return {void}
		 */
		onEditNoteClick(call, note) {
			this.panels.notes.update = true

			this.note = { ...note, call_id: call.id }
		},

		/**
		 * Handle the user starting to edit the patient's DOB transcription.
		 *
		 * @return {void}
		 */
		async onEditDob() {
			this.dob.edit(this.call.patient_dob)

			await this.$nextTick()

			this.$refs.dob.focus()
		},

		/**
		 * Handle the user starting to edit the patient's message transcription.
		 *
		 * @return {void}
		 */
		async onEditMessage() {
			this.message.edit(this.call.patient_message)

			await this.$nextTick()

			this.$refs.message.focus()
		},

		/**
		 * Handle the user starting to edit the patient's name transcription.
		 *
		 * @return {void}
		 */
		async onEditName() {
			this.name.edit(this.call.patient_name)

			await this.$nextTick()

			this.$refs.name.focus()
		},

		/**
		 * Handle the user starting to edit the patient's Pharmacy Number transcription.
		 *
		 * @return {void}
		 */
		async onEditPharmacyNumber() {
			this.pharmacyNumber.edit(this.call.pharmacy_phone_number)
			await this.$nextTick()
			this.$refs.pharmacyNumber.focus()
		},

		/**
		 * Handle the route/current call change event.
		 *
		 * @return {void}
		 */
		async onRouteChange(to, from) {
			this.dob.reset()
			this.name.reset()
			this.message.reset()
			this.pharmacyNumber.reset()

			if (
				from &&
				to.params.sid &&
				to.params.partner !== from.params.partner
			) {
				return this.$router.push({
					name: 'app.calls',
					params: { partner: to.params.partner },
				})
			}

			await [this.getCall(to.params.sid), this.getCallTypes()]
		},

		/**
		 * Handle the message save event.
		 *
		 * @param {MouseEvent|KeyboardEvent}
		 * @return {void}
		 */
		async onSaveDob(event) {
			this.dob.saving = true

			if (event.target === this.$refs.dob) {
				this.dob.update(event)
			}

			await this.$nextTick()

			await this.updateTranscription('patient_dob', this.dob.value)

			this.dob.reset()
		},

		/**
		 * Handle the message save event.
		 *
		 * @param {MouseEvent|KeyboardEvent}
		 * @return {void}
		 */
		async onSaveMessage(event) {
			this.message.saving = true

			if (event.target === this.$refs.message) {
				this.message.update(event)
			}

			await this.$nextTick()

			await this.updateTranscription(
				'patient_message',
				this.message.value
			)

			this.message.reset()
		},

		/**
		 * Handle the message save event.
		 *
		 * @param {MouseEvent|KeyboardEvent}
		 * @return {void}
		 */
		async onSaveName(event) {
			this.name.saving = true

			if (event.target === this.$refs.name) {
				this.name.update(event)
			}

			await this.$nextTick()

			await this.updateTranscription('patient_name', this.name.value)

			this.name.reset()
		},

		/**
		 * Handle the pharmacy Number save event.
		 *
		 * @param {MouseEvent|KeyboardEvent}
		 * @return {void}
		 */
		async onSaveNumber(event) {
			this.pharmacyNumber.saving = true
			if (event.target === this.$refs.pharmacyNumber) {
				this.pharmacyNumber.update(event)
			}
			await this.$nextTick()
			await this.updateTranscription(
				'pharmacy_phone_number',
				this.pharmacyNumber.value
			)
			this.pharmacyNumber.reset()
		},

		/**
		 * Update a the call message, patient name or DOB transcription.
		 *
		 * @param {String} key
		 * @param {String} value
		 * @return {Promise}
		 */
		updateTranscription(key, value) {
			return this.$store.dispatch('calls/updateCallAttribute', {
				key,
				value,
				id: this.call.id,
			})
		},

		...mapActions('calls', ['removeRecentCall']),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Show',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for changes.
		 */
		$route: {
			immediate: true,
			handler: 'onRouteChange',
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			callTypes: [],
			dob: new Editable(),
			message: new Editable(),
			name: new Editable(),
			pharmacyNumber: new Editable(),
			note: null,
			panels: {
				complete: false,
				notes: {
					create: false,
					delete: false,
					update: false,
				},
				reassign: false,
			},
			tab: 'message',
		}
	},
}
</script>
